export const STORY_LIST_REQUEST = "STORY_LIST_REQUEST";
export const STORY_LIST_SUCCESS = "STORY_LIST_SUCCESS";
export const STORY_LIST_FAIL = "STORY_LIST_FAIL";

export const STORY_DETAILS_REQUEST = "STORY_DETAILS_REQUEST";
export const STORY_DETAILS_SUCCESS = "STORY_DETAILS_SUCCESS";
export const STORY_DETAILS_FAIL = "STORY_DETAILS_FAIL";
export const STORY_DETAILS_RESET = "STORY_DETAILS_RESET";

export const STORY_UPDATE_REQUEST = "STORY_UPDATE_REQUEST";
export const STORY_UPDATE_SUCCESS = "STORY_UPDATE_SUCCESS";
export const STORY_UPDATE_FAIL = "STORY_UPDATE_FAIL";
export const STORY_UPDATE_RESET = "STORY_UPDATE_RESET";

export const STORY_TOP_REQUEST = "STORY_TOP_REQUEST";
export const STORY_TOP_SUCCESS = "STORY_TOP_SUCCESS";
export const STORY_TOP_FAIL = "STORY_TOP_FAIL";
